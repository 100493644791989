
<ng-template #customLoadingTemplate>
        <div class="custom-class-loader">
            <div class="spinner-container">
                <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                    <circle cx="170" cy="170" r="160" stroke="#021e48" />
                    <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                    <circle cx="170" cy="170" r="110" stroke="#021e48" />
                    <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
                </svg>
            </div>
        </div>
</ng-template>

<ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<nav #leftNavigation class="left-navbar sidebar qma-background" [class.left-navbar-expanded]="hideSubMenu" [ngClass]="{showMobileNav: shouldShow, hideMobileNav: !shouldShow}">
     <div class='text-center logo py-3'>
        <img class="small-logo" *ngIf="!hideSubMenu" src='assets/core/layout/nav/qma_icon_small.png' alt="Hide">
        <img class="medium-logo" *ngIf="hideSubMenu" src='assets/core/layout/nav/qma_icon_medium.png' alt="Show">
        <div class="close-leftNav" (click)="closeNavigation()">
            <img src='assets/mobile/close.svg' alt="Close">
        </div>
    </div>

    <ul class="nav d-flex align-items-center flex-column flex-nowrap qma-background menu-wrapper" [class.hideAllSubMenu]="!hideSubMenu">

          <li class="w-100 mobile-profile-menu">
            <div class="nav d-flex align-items-center flex-column">
                <div class="image-holder">
                    <img class="profile-icon-img" [src]="profilePic" *ngIf="profilePic" alt="Profile">
                    <div class="display-individual" *ngIf="!profilePic">{{userId.substring(0,2)}}</div>
                 </div>
                 <div class="menu-text">{{userName}}</div>
            </div>
         </li>
          <li class="w-100"  [class.active]='activeMenuArray[0]' *ngIf="!symphonyEnabled && !taskizeEnabled">
              <div appDebounceClick (debounceClick)="onMenuClick(0, false, 'NEW MESSAGE', 0)" [debounceTimeConst]="300" class="nav d-flex align-items-center flex-column">
                   <div class="image-holder"><!--  <i class="fa fa-file"></i> -->
                        <img [src]="!activeMenuArray[0] ? 'assets/core/layout/nav/new_message.svg' : 'assets/core/layout/nav/new_message_selected.svg'"  alt="">
                    </div>
                  <div class="menu-text">NEW MESSAGE</div>
              </div>
          </li>
        <li class="w-100" [class.active]='activeMenuArray[0]' *ngIf="symphonyEnabled || taskizeEnabled">
            <div (click)="onMenuClick(0, true)" class="nav d-flex align-items-center flex-column mobile-views">
                <div class="image-holder"><!-- <i class="fa fa-rocket"></i> -->
                    <img [src]="!activeMenuArray[0] ? 'assets/core/layout/nav/reports.svg' : 'assets/core/layout/nav/reports_selected.svg'"  alt="">
                </div>
              <div class="menu-text">NEW MESSAGE</div>
              <div class="d-none chevron-holder">
                    <em class="fa " [ngClass]="activeMenuArray[0] ? 'fa-chevron-up': 'fa-chevron-down'"></em>
                </div>
          </div>
          <div>
            <ul *ngIf="activeMenuArray[0]" [@panelInOut] class="d-flex flex-column flex-nowrap subMenu">
                <li><a appDebounceClick (debounceClick)="onMenuClick(0, false, 'NEW MESSAGE', 0)" [debounceTimeConst]="300">New Email</a><span class="pull-right"></span></li>
                <li *ngIf = "symphonyEnabled"><a (click)="showSympCreateChatPopup()">New Chat Room</a><span class="pull-right"></span></li>
                <li *ngIf = "taskizeEnabled"><a appDebounceClick (debounceClick)="onMenuClick(0, false, 'NEW BUBBLE', 0)" [debounceTimeConst]="300">New Taskize Bubble</a><span class="pull-right"></span></li>
                <app-symphony-create-chat [displayChatRoomPopUp]="displayChatRoomPopUp" #sympcrtChatComp
                (showHideSymphonySpinner)="showHideSymphonySpinner($event)" (onSympCrtChatPopupClose)="onSympCrtChatPopupClose($event)"></app-symphony-create-chat>
            </ul>
           </div>
        </li>
          <li class="w-100" [class.active]='activeMenuArray[1]'>
              <div (click)="onMenuClick(1, false, 'Dashboard')" class="nav d-flex align-items-center flex-column">
                    <div class="image-holder"><!-- <i class="fa fa-dashboard"></i> -->
                        <img [src]="!activeMenuArray[1] ? 'assets/core/layout/nav/dashboard.svg' : 'assets/core/layout/nav/dashboard_selected.svg'"  alt="">
                    </div>
                    <div class="menu-text">DASHBOARD</div>
              </div>
          </li>
          <li class="w-100" [class.active]='activeMenuArray[3]' >
              <div (click)="onMenuClick(3, true)" class="nav d-flex align-items-center flex-column">
                  <div  class="image-holder"><!-- <i class="fa fa-envelope"></i> -->
                    <img [src]="!activeMenuArray[3] ? 'assets/core/layout/nav/message.svg' : 'assets/core/layout/nav/message_selected.svg'"  alt="">
                  </div>
                  <div class="menu-text">MESSAGES</div>
                  <div class="d-none chevron-holder">
                      <em class="fa " [ngClass]="activeMenuArray[3] ? 'fa-chevron-up': 'fa-chevron-down'"></em>
                  </div>
              </div>
              <div *ngIf="activeMenuArray[3]" [@panelInOut]>
                <ul class="d-flex flex-column flex-nowrap subMenu">
                    <li *ngFor="let item of msgItems">
                       <a (click)=" onClickMenuItem(item.label, item.count)">{{item.name}}</a>
                       <span *ngIf="item.count>0" class="custom-badge pull-right" data-toggle="tooltip" data-placement="right" [attr.title]="item.count"><span>{{displayEllipsis(item.count)}}</span></span>
                        <div *ngIf="item.childList" class="chevron-holder pull-right">
                            <em (click)="onTagsClick(0)" style="font-size: 20px;padding-right: 3px;" class="fa " [ngClass]="activeTagsMenu[0] ? 'fa-chevron-up': 'fa-chevron-down'"></em>
                        </div>

                       <ul class="message-childList p-0 mt-2 w-100" *ngIf="item.childList" [hidden]="!activeTagsMenu[0]">
                         <li *ngFor="let listItem of item.childList" class="d-flex d-column align-items-center justify-content-between">
                            <a (click)="onClickMenuItemSubMenu(listItem.label, listItem.count, listItem.name)"><ins>{{listItem.name}}</ins></a>
                            <span *ngIf="listItem.count > 0" class="custom-badge pull-right"  data-toggle="tooltip" data-placement="right" [attr.title]="listItem.count"><span>{{displayEllipsis(listItem.count)}}</span></span>
                        </li>
                       </ul>
                    </li>
                  </ul>
                  <div class="tags-holder mt-1">
                        <div (click)="onTagsClick(1)" class="d-flex align-items-center flex-row">
                            <div class="tag-image-holder"><!-- <i class="fa fa-envelope"></i> -->
                                <img [src]="activeTagsMenu[1] ? 'assets/core/layout/nav/group_tag.svg' : 'assets/core/layout/nav/group_tag_selected.svg'"  alt="Tag">
                            </div>
                            <div class="tags-text">Group tags</div>
                            <div class="d-none chevron-holder">
                                <em class="fa " [ngClass]="activeTagsMenu[1] ? 'fa-chevron-up': 'fa-chevron-down'"></em>
                            </div>
                        </div>
                    <div *ngIf="activeTagsMenu[1]" class="mt-2">
                        <app-collapsible-list [placeholder]='tagsSearchPlaceholder' [items]='groupTagItems' (onItemSelect)="OnGroupTagSelect($event)"></app-collapsible-list>
                    </div>
                  </div>
              </div>
          </li>
          <li class="w-100"  [class.active]='activeMenuArray[4]'>
              <div (click)="onMenuClick(4, true)" class="nav d-flex align-items-center flex-column">
                  <div class="image-holder"><!-- <i class="fa fa-inbox"></i> -->
                    <img [src]="!activeMenuArray[4] ? 'assets/core/layout/nav/my_mailbox.svg' : 'assets/core/layout/nav/my_mailbox_selected.svg'"  alt="">
                    </div>
                  <div class="menu-text">MY VIEWS</div>
                  <div class="d-none chevron-holder">
                      <em class="fa " [ngClass]="activeMenuArray[4] ? 'fa-chevron-up': 'fa-chevron-down'"></em>
                  </div>
              </div>
              <div *ngIf="activeMenuArray[4]"  [@panelInOut] class="subMenu search-class">
                <div class="d-flex align-items-center mb-2 mt-2 create-tag mobile-views" (click)="openMyViewsPopUp()">
                    <img src="assets/core/layout/nav/add_icon.svg" alt="Create">
                    <span><ins>Create new</ins></span>
                </div>
                <app-search-list [name]="'myViews'" [placeholder]='viewSearchPlaceholder' [items]='myViewsItems' [listStyle]="{'height':'180px','text-decoration':'underline'}" (onItemSelect)="onMyViewsItem($event)"></app-search-list>
            </div>
          </li>
          <li class="w-100"  [class.active]='activeMenuArray[5]'>
              <div (click)="onMenuClick(5, true)" class="nav d-flex align-items-center flex-column mobile-views">
                    <div class="image-holder"><!-- <i class="fa fa-rocket"></i> -->
                        <img [src]="!activeMenuArray[5] ? 'assets/core/layout/nav/reports.svg' : 'assets/core/layout/nav/reports_selected.svg'"  alt="">
                    </div>
                  <div class="menu-text">REPORTS</div>
                  <div class="d-none chevron-holder">
                        <em class="fa " [ngClass]="activeMenuArray[5] ? 'fa-chevron-up': 'fa-chevron-down'"></em>
                    </div>
              </div>
              <div>
                <ul *ngIf="activeMenuArray[5]" [@panelInOut] class="d-flex flex-column flex-nowrap subMenu">
                    <li *ngIf="hasGroupRole"><a (click)="openMyReportPopUp()">Inquiry Reports</a><span class="pull-right"></span></li>
                    <li><a (click)="openMISDashboard()">MIS Dashboard</a><span class="pull-right"></span></li>
                </ul>
               </div>
          </li>
          <li *ngIf='showOldSymphony' class="w-100"  [class.active]='activeMenuArray[6]'>
                <div (click)="onMenuClick(6, true)" class="nav d-flex align-items-center flex-column">

                      <em class="fa fa-comment-o" aria-hidden="true"></em>
                    <div class="menu-text">SYMPHONY</div>
                    <div class="d-none chevron-holder">
                        <em class="fa " [ngClass]="activeMenuArray[6] ? 'fa-chevron-up': 'fa-chevron-down'"></em>
                    </div>
                </div>
                <div *ngIf="activeMenuArray[6]"  [@panelInOut] class="subMenu search-class symphonyChat">
                  <app-symphony-side-bar (streamId)="openSymphonyChatView($event)" ></app-symphony-side-bar>
              </div>
            </li>
            <li class="w-100" [class.active]='activeMenuArray[7]' *ngIf="calendarSubscribed">
                <div (click)="onMenuClick(7, false, 'CALENDAR')" class="nav d-flex align-items-center flex-column mobile-views">
                    <div class="image-holder">
                        <em class="fa fa-calendar" aria-hidden="true"></em>
                    </div>
                    <div class="menu-text">CALENDAR</div>
                </div>
            </li>
            <li class="w-100"  [class.active]='activeMenuArray[8]' *ngIf="personalMailSubscribed">
                <div (click)="onMenuClick(8, true)" class="nav d-flex align-items-center flex-column mobile-views">
                    <div class="image-holder">
                        <em class="fa fa-folder-o" aria-hidden="true"></em>
                    </div>
                    <div class="menu-text">PERSONAL EMAIL</div>
                    <div class="d-none chevron-holder">
                        <em class="fa " [ngClass]="activeMenuArray[8] ? 'fa-chevron-up': 'fa-chevron-down'"></em>
                    </div>
                </div>
                <div *ngIf="activeMenuArray[8]"  [@panelInOut] class="subMenu search-class symphonyChat">
                  <app-personal-email></app-personal-email>
              </div>
            </li>
       </ul>
</nav>
<!--Responsive Design-->
<p-dialog [(visible)]="myViewBoolean" modal="true" class="myView" [styleClass]="'viewModal'" [style]="{'top':'120px !important','border':'1px solid #002d72','background':'#fff'}"  appendTo="body" showHeader="true" [resizable]="false" closable="false">
        <p-header>
            <div class="d-flex flex-row align-items-center justify-content-between" style="margin-left:27px">
                <!-- <h6 class="gfid-title" style="font-size: 20px">MY VIEW CRITERIA</h6> -->
                <div class="header" style="width: 100%;">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <div>
                            <h6 _ngcontent-qyn-c159="" class="gfid-title ng-tns-c159-5" style="font-size: 20px;">MY VIEW CRITERIA</h6>
                        </div>
                        <div>
                            <button type="button" (click)="hideMyViewModal()" class="d-flex flex-row close " style="border:none !important;opacity:1;color:#002d72;">
                                <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
                                <h6>CLOSE</h6>
                            </button>
                        </div>
                    </div>
                    <div>
                <div style="font-weight: normal;font-size: 12px;font-family: 'InterstateLight';">
                    <img _ngcontent-bnh-c182="" src="assets/Inbox/info-icon.svg" alt="" class="icon-setting-out ng-tns-c182-10">
                    Last {{allowedMonthsInMyViews}} months data accessible from My view. Use QMA search function for old records.
                </div>
            </div>
                </div>

            </div>
        </p-header>
        <app-myviews [myViewsItems]="myViewsItems"></app-myviews>
</p-dialog>
<!-- Responsive Design-->
<p-dialog [(visible)]="myReportBoolean" [styleClass]="'reportModal'" modal="true" class="myView" [style]="{'top':'120px !important','border':'1px solid #002d72','background':'#fff'}" appendTo="body" showHeader="false" closeIcon="" [resizable]="false">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between" style="margin-left:27px">
            <h6 class="gfid-title" style="font-size: 20px">REQUEST MIS REPORT</h6>

            <button type="button" (click)="hideReportModal($event)" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1;outline: none;">
                <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <app-reports (modalClosed)="hideReportModal($event)" [modalOpen]="myReportBoolean"></app-reports>
</p-dialog>
<app-action-popup></app-action-popup>

<!-- symphony changes -->
<p-dialog [(visible)]="isServiceSymponyError" [styleClass]="'symphony-service-error'"
header= " Symphony services unavailable please try again later"
[style]="{'font-size': '12px !important','font-family':'InterstateLight !important','color': '#002d72 !important','text-transform': 'none !important'}">


 </p-dialog>

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MyGroup } from 'src/app/model/LoginUserInfo/MyGroup';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { UserDataService } from 'src/app/services/user-data.service';
import { InboxService } from "src/app/services/inbox.service";
import { GfidSearchComponent } from '../gfid-search/gfid-search.component';
import { PiwikProUtils } from '../../utility/piwikProUtils';
import { TaskizeAuthService } from 'src/app/services/taskize-auth.service';

@Component({
  selector: 'app-resolve-inquiry',
  templateUrl: './resolve-inquiry.component.html',
  styleUrls: ['./resolve-inquiry.component.scss']
})
export class ResolveInquiryComponent implements OnInit {
  idsWorkFlowLists: any;
  loginUserInfo: any;
  display = false;
  displayStatic = false;
  resolveInquiry: any;
  selectedMenuItem: any;
  contextServiceObject: any;
  allGroupNames: any[] = [];
  groupNames: any[] = [];
  commonResolveInquirySourceList = [];
  inquirySourceList = [];
  requestTypes = [];
  tags = [];
  processingRegions = [];
  rootCauses = [];
  requestTypeMap = new Map<string, string[]>();
  processingRegionMap = new Map<string, string[]>();
  rootCauseMap = new Map<string, string[]>();
  tagMap = new Map<string, string[]>();
  grpName: string;
  mandatoryForResolveFlags = [];
  submitted = false;
  resolveAll = false;
  inquiryIdList = [];
  hideQueries = false;
  message: string;
  disableSubmit = false;
  loggedUserName: string;
  gfidMissingErr: string;
  resolveErrorMessage: string;
  commonRequestTypeGroupList = [];
  isGfidMandatory = false;
  isGfidAvailable = true;
  requestTypeErrorMessageForAllGroup: string;
  tagErrorMessageForAllGroup: string;
  rootCauseErrorMessageForAllGroup: string;
  processingRegionErrorMessageForAllGroup: string;
  inqSourceErrorMessageForAllGroup: string;
  isTagMandatory = false;
  isTagAvailable = true;
  isRootCauseMandatory = false;
  isProcessingRegionMandatory = false;
  isInqSourceMandatory = false;
  rootCauseRequired = [];
  highlevelreqtypeFlag = false;
  displayError = false;
  errMsg = '';
  lockErrorMsg = '';
  @ViewChild('checkBoxForceResolveDiv', { static: true }) checkBoxForceResolveDiv: ElementRef;
  loggedInUser = '';
  loggedInUserName = '';
  hide = false;

  //Request Type and Root Cause Linking
  reqTypeRootCauseMap = new Map<string, any>();
  /*  changes regarding C170665-365If GFPID is mandatory,provide an option to assign gfcid click on resolve option */
  //Navigation: Resolved screen UI changes
  toggleMoreFields: boolean = false;
  showMoreFields: boolean = false;
  @ViewChild(GfidSearchComponent) GfidSearchComponent: any;
  inquirySubStatusList: any[];
  enableInquirySubStatusFlag: any;
  isTaskizeInquiry: boolean;
  isSubmitClicked: boolean;
  isRootCauseSet: boolean;
  constructor(private contextMenuService: ContextMenuService, private builder: FormBuilder,
    private userDataService: UserDataService, private inboxService: InboxService, private taskizeService: TaskizeAuthService) {
    this.contextServiceObject = contextMenuService;
  }

  resolveInquiryForm: FormGroup = this.builder.group({
    groupName: new FormControl('', [Validators.required]),
    requestType: new FormControl('', [Validators.required]),
    tags: new FormControl('', []),
    inquirySource: new FormControl('', []),
    rootCause: new FormControl('', []),
    processingRegion: new FormControl('', []),
    queryCount: new FormControl('', [Validators.min(1), Validators.max(9999)]), // Query count validation
    comments: new FormControl('', []),
    resolveAll: new FormControl('', []),
    forceResolve: new FormControl('', []),
    resolveAllocation: new FormControl('', []),
    inquirySubStatus: new FormControl('', []) //DCC Requirement: Add Case status field
  });

  get add() { return this.resolveInquiryForm.controls; }

  ngOnInit() {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.loginUserInfo = loginUserInfo
      this.enableInquirySubStatusFlag = loginUserInfo.enableInquirySubStatusFlag;
    });
    this.triggerFalseClick(true);

  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onResolveSubmit() {
    this.isSubmitClicked = true;
    if (this.validateFormFields()) {
      this.validateAllFormFields(this.resolveInquiryForm);
    }
    else {
      this.resolveInquiryService();
    }
  }

  resetResolveInquiry(form: FormGroup) {
    this.inquiryIdList = [];
    this.hideQueries = false;
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
    form.markAsPristine();
    form.markAsUntouched();
    form.updateValueAndValidity();
    this.resolveAll = false;
    const forceResolve = this.resolveInquiryForm.get('forceResolve').value;
    if (forceResolve) {
      this.disableSubmit = false;
      this.checkBoxForceResolveDiv.nativeElement.style.setProperty('display', 'flex', 'important');
      this.lockErrorMsg = '';
      this.resolveInquiryForm.get('forceResolve').setValue(true);
    }
    else if (!this.isNull(forceResolve)) {
      this.disableSubmit = true;
      this.checkBoxForceResolveDiv.nativeElement.style.setProperty('display', 'flex', 'important');
      this.lockErrorMsg = this.errMsg;
      this.resolveInquiryForm.get('forceResolve').setValue(false);
    } else if (this.isTaskizeInquiry) {
      this.taskizeResolve()
    }
    else { // Check whether Gfid is mandatory and available then disable submit button
      if (this.isGfidMandatory && !this.isGfidAvailable)
        this.disableSubmit = true;
      else {
        this.disableSubmit = false;
        this.checkBoxForceResolveDiv.nativeElement.style.setProperty('display', 'none', 'important');

        this.resolveInquiryForm.get('forceResolve').setValue(false);
      }
    }
  }

  triggerFalseClick(fromOninit?: boolean) {
    try {
      this.isSubmitClicked = false;
      this.resolveInquiryForm.reset();
      if (this.loginUserInfo && this.loginUserInfo.taskizeConfig && this.loginUserInfo.taskizeConfig.taskizeEnabled && this.loginUserInfo.isTaskizeEnabledForUser && this.loginUserInfo.isTaskizeEnabledForUser?.toUpperCase() == 'Y') {
        this.isTaskizeInquiry = this.taskizeService.checkIsTaskizeInquiry()
      }
      if (this.isTaskizeInquiry) {
        this.taskizeResolve()
      } else {
        this.resolveInquiryForm.get('resolveAll').setValue(false);
        this.resolveInquiryForm.controls['resolveAll'].enable();
      }
      this.resolveInquiryForm.get('resolveAllocation').setValue(false);
      if (!fromOninit) {
        this.display = true;
        // reset show more panel
        this.toggleMoreFields = false;
        this.showMoreFields = false;
      }
      this.message = '';
      this.gfidMissingErr = '';
      this.resolveErrorMessage = '';
      this.requestTypeErrorMessageForAllGroup = '';
      this.tagErrorMessageForAllGroup = '';
      this.rootCauseErrorMessageForAllGroup = '';
      this.processingRegionErrorMessageForAllGroup = '';
      this.inqSourceErrorMessageForAllGroup = '';
      this.disableSubmit = false;
      this.hideQueries = false;
      this.resolveAll = false;
      this.hide = false;
      this.grpName = ""; // Reset the selected group.

      const workflows = [];
      const groupNameTmp = [];

      if (this.selectedMenuItem) {
        for (const menuitem of this.selectedMenuItem) {
          this.inquiryIdList.push(menuitem._id);
          workflows.push(menuitem.workflows);
        }
      }
      this.showSubmitHideForceResolveMsg();
      this.isLockedSameAsLoginUser(this.inquiryIdList, this.selectedMenuItem);

      if ((this.inquiryIdList.length > 1 && this.resolveAll) || this.resolveAll || this.inquiryIdList.length > 1) {
        this.hideQueries = true;
      }
      this.idsWorkFlowLists = this.getCommonGroupIds(this.inquiryIdList.join(","), workflows, 'IN', 'OPEN');
      // :Resolve not working for Sent folder will be uncomment for sent folder
      // if (this.selectedMenuItem[0].direction === 'OUT') {
      //   this.idsWorkFlowLists = this.getCommonGroupIdsWithoutStatus(this.inquiryIdList.join(","), workflows, 'OUT');
      // } else {
      //   this.idsWorkFlowLists = this.getCommonGroupIds(this.inquiryIdList.join(","), workflows, 'IN', 'OPEN');
      // }
      const workFlow = this.idsWorkFlowLists[0].commonGroups;
      this.allGroupNames = this.populateAllGroupNames();

      for (const group of this.allGroupNames) {
        let version = '';
        version = group.id + 'IN';
        // C170665-325 :Resolve not working for Sent folder will be uncomment for sent folder
        // if (workflows[0][0].direction === 'OUT') {
        //   version = group.id + 'OUT';
        // } else {
        //   version = group.id + 'IN';
        // }
        if (group.value != null && group.id != null && (workFlow.indexOf(version) > -1)) {
          groupNameTmp.push({ label: group.value, value: group.value });
        }
      }
      this.groupNames = groupNameTmp;
      // warning on 'common resolver group' only when more than one inquiry are selected.
      // if (!this.groupNames.length && this.selectedMenuItem.length > 1) {
      if (this.groupNames.length > 1) {
        this.resolveErrorMessage = "Selected inquiries do not have a common resolver group";
        this.disableSubmit = true;
        this.hide = true;
        return;
      }

      if (!this.isNull(this.groupNames)) {
        if (this.groupNames.length === 1) {

          this.grpName = this.groupNames[0].label;
          this.populateRequestType(this.grpName);
          this.populateTags(this.grpName);
          this.populateProcessingRegion(this.grpName);
          this.populateRootCause(this.grpName);
          this.populateInquirySource();
          this.populateInquirySubStatus();
          this.mandatoryForResolveParametersForGroupName(this.grpName);
          console.log('this.mandatoryForResolveFlags: ', this.mandatoryForResolveFlags);
          this.isGfidMandatory = this.mandatoryForResolveFlags[0].isGfidMandatory;
          this.isGfidAvailable = this.isGfidAvailableForResolve(this.selectedMenuItem);
          this.isTagMandatory = this.mandatoryForResolveFlags[0].isTagMandatory;

          if (this.isGfidMandatory && !this.isGfidAvailable) {
            this.gfidMissingErr = "GFPID/GFCID is missing. Inquiry(s) cannot be resolved.";
            this.disableSubmit = true;
          }
          // Populate the Default items
          const selWorkFlow: any = workflows[0][0];
          if (!this.isNull(selWorkFlow.requestType)) {
            this.resolveInquiryForm.get('requestType').setValue(selWorkFlow.requestType);
            this.resolveInquiryForm.controls['requestType'].markAsTouched();
            this.resolveInquiryForm.controls['requestType'].markAsDirty();
          }
          if (!this.isNull(selWorkFlow.tag)) {
            this.resolveInquiryForm.get('tags').setValue(selWorkFlow.tag);
            this.resolveInquiryForm.controls['tags'].markAsTouched();
            this.resolveInquiryForm.controls['tags'].markAsDirty();
          }
          // C170665-10 | Request Type and Root Cause Linking
          // C170665-279 | Scenario - When root cause is not assigned to inquiry, it should be populated with default root causes.
          if (!this.isNull(selWorkFlow.requestType)) {
            // Populate the Root Causes based on Request Type.
            this.populateRootCause(this.grpName, this.resolveInquiryForm.get('requestType').value);
            let rootCause = selWorkFlow.rootCause || "";
            /**
             * C170665-10 | Request Type and Root Cause Linking
             * This is required for existing inquiry that have request type and root cause but don't have any mapping now.
             */
            if (!this.isNull(rootCause)) {
              let idx = this.rootCauses.findIndex(rc => rc.value == rootCause);
              let rootCauseList = this.rootCauseMap.get(this.grpName);
              if (rootCauseList && rootCauseList.includes(rootCause) && idx == -1) {
                this.rootCauses.push({ label: rootCause, value: rootCause });
              }
            }
            if (this.rootCauses.length == 2 && this.isNull(rootCause)) {
              rootCause = this.rootCauses[1].value;
            }

            if (!this.isRootCauseSet)
              this.resolveInquiryForm.get('rootCause').setValue(rootCause);
            this.resolveInquiryForm.controls['rootCause'].markAsTouched();
            this.resolveInquiryForm.controls['rootCause'].markAsDirty();
          }

          if (!this.isNull(selWorkFlow.processingRegion)) {
            this.resolveInquiryForm.get('processingRegion').setValue(selWorkFlow.processingRegion);
            this.resolveInquiryForm.controls['processingRegion'].markAsTouched();
            this.resolveInquiryForm.controls['processingRegion'].markAsDirty();
          }
          //- Processing Region field left empty in Resolved folder
          else if (this.isNull(selWorkFlow.processingRegion) && this.processingRegions.length == 1) {
            this.resolveInquiryForm.get('processingRegion').setValue(this.processingRegions[0].value);
          }

          if (!this.isNull(selWorkFlow.inquirySource)) {
            this.resolveInquiryForm.get('inquirySource').setValue(selWorkFlow.inquirySource);
            this.resolveInquiryForm.controls['inquirySource'].markAsTouched();
            this.resolveInquiryForm.controls['inquirySource'].markAsDirty();
          }
          //DCC Requirement: Add Case status field
          if (!this.isNull(selWorkFlow.inquirySubStatus) && this.enableInquirySubStatusFlag) {
            this.resolveInquiryForm.get('inquirySubStatus').setValue('Completed');
            this.resolveInquiryForm.controls['inquirySubStatus'].markAsTouched();
            this.resolveInquiryForm.controls['inquirySubStatus'].markAsDirty();
          }
          // Populate Query Count.
          if (!this.isNull(selWorkFlow.queryCount)) {
            this.resolveInquiryForm.get('queryCount').setValue(selWorkFlow.queryCount);
            this.resolveInquiryForm.controls['queryCount'].markAsTouched();
            this.resolveInquiryForm.controls['queryCount'].markAsDirty();
          }
        }
      }
    }
    catch (err) {
      console.log("QMA2.0: ResolveInquiryComponent::triggerFalseClick, error in binding data, and the ERROR is " + err.stack);
      this.inboxService.saveUILog("QMA2.0: ResolveInquiryComponent::triggerFalseClick, error in binding data, and the ERROR is " + err.stack).subscribe(res => {
        console.log(res);
      });
    }
  }

  validateFormFields(): boolean {
    let valid = false;

    if (this.enableInquirySubStatusFlag && this.mandatoryForResolveFlags[0].enableInquirySubStatus &&
      (this.isNull(this.resolveInquiryForm.controls['inquirySubStatus'].value))) {
      this.resolveInquiryForm.controls['inquirySubStatus'].setValidators(Validators.required);
      this.resolveInquiryForm.controls['inquirySubStatus'].setErrors({ notUnique: true });
      this.resolveInquiryForm.controls['inquirySubStatus'].updateValueAndValidity();
      console.log('Inquiry Sub-Status should be mandatory');
      valid = true;
    }
    else {
      this.resolveInquiryForm.get('inquirySubStatus').clearValidators();
      this.resolveInquiryForm.get('inquirySubStatus').setErrors(null);
      this.resolveInquiryForm.get('inquirySubStatus').updateValueAndValidity();
    }

    if (this.mandatoryForResolveFlags[0].isProcessingRegionMandatory &&
      (this.isNull(this.resolveInquiryForm.controls['processingRegion'].value))) {
      this.resolveInquiryForm.controls['processingRegion'].setValidators(Validators.required);
      this.resolveInquiryForm.controls['processingRegion'].setErrors({ notUnique: true });
      this.resolveInquiryForm.controls['processingRegion'].updateValueAndValidity();
      console.log('Processing region should be mandatory');
      valid = true;
    }
    else {
      this.resolveInquiryForm.get('processingRegion').clearValidators();
      this.resolveInquiryForm.get('processingRegion').setErrors(null);
      this.resolveInquiryForm.get('processingRegion').updateValueAndValidity();
    }

    if ((this.mandatoryForResolveFlags[0].isRootCauseMandatory ||
      this.mandatoryForResolveFlags[0].isRootCauseMandatoryWOReply) &&
      (this.isNull(this.resolveInquiryForm.controls['rootCause'].value))) {

      this.resolveInquiryForm.controls['rootCause'].setValidators(Validators.required);
      this.resolveInquiryForm.controls['rootCause'].setErrors({ notUnique: true });
      this.resolveInquiryForm.controls['rootCause'].updateValueAndValidity();
      console.log('Root Cause should be mandatory');
      valid = true;
    }
    else {
      this.resolveInquiryForm.get("rootCause").clearValidators();
      this.resolveInquiryForm.get("rootCause").setErrors(null);
      this.resolveInquiryForm.get("rootCause").updateValueAndValidity();
    }

    if (this.mandatoryForResolveFlags[0].isTagMandatory
      && (this.isNull(this.resolveInquiryForm.controls['tags'].value))) {
      this.resolveInquiryForm.controls['tags'].setValidators(Validators.required);
      this.resolveInquiryForm.controls['tags'].setErrors({ notUnique: true });
      this.resolveInquiryForm.controls['tags'].updateValueAndValidity();
      console.log('Tags should be mandatory');
      valid = true;
    }
    else {
      this.resolveInquiryForm.get("tags").clearValidators();
      this.resolveInquiryForm.get("tags").setErrors(null);
      this.resolveInquiryForm.get("tags").updateValueAndValidity();
    }

    if (this.mandatoryForResolveFlags[0].isInqSourceMandatory
      && (this.isNull(this.resolveInquiryForm.controls['inquirySource'].value))) {
      this.resolveInquiryForm.controls['inquirySource'].setValidators(Validators.required);
      this.resolveInquiryForm.controls['inquirySource'].setErrors({ notUnique: true });
      this.resolveInquiryForm.controls['inquirySource'].updateValueAndValidity();
      console.log('Inquiry Source should be mandatory');
      valid = true;
    }
    else {
      this.resolveInquiryForm.get('inquirySource').clearValidators();
      this.resolveInquiryForm.get('inquirySource').setErrors(null);
      this.resolveInquiryForm.get('inquirySource').updateValueAndValidity();
    }

    if (!this.resolveAll && this.resolveInquiryForm.controls.queryCount.errors) {
      console.log('Query Count have Min and Max Validations');
      valid = true;
    }
    // Query count updates not saved while resolving Inquiry from context menu
    else if (this.resolveAll) {
      this.resolveInquiryForm.get('queryCount').setValue(null);
      this.resolveInquiryForm.get('queryCount').setErrors(null);
    }

    if (this.resolveInquiryForm.controls.requestType.errors
    ) {
      valid = true;
    }

    return valid;
  }

  hideModal() {
    this.inquiryIdList = [];
    this.display = false;
    this.hideQueries = false;
  }

  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  populateAllGroupNames(): MyGroup[] {
    const groupNameTmp = [];
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.highlevelreqtypeFlag = loginUserInfo.highlevelRequestTypeFlag;
      loginUserInfo.myGroups.forEach(myGroup => {
        groupNameTmp.push({ label: myGroup.groupName, value: myGroup.groupName, id: myGroup.id });
        this.requestTypeMap.set(myGroup.groupName, myGroup.requestTypes);
        this.processingRegionMap.set(myGroup.groupName, myGroup.processingRegionList);
        this.rootCauseMap.set(myGroup.groupName, myGroup.rootCauseList);
        this.tagMap.set(myGroup.groupName, myGroup.tags);

        // C170665-10 | Populate the map of group with request type and root cause mapping.
        if (myGroup.requestTypeRootCauseFilter) {
          this.reqTypeRootCauseMap.set(myGroup.groupName, myGroup.requestTypeRootCauseMapping);
        }
      });
      this.loggedInUser = loginUserInfo.userId;
      this.loggedInUserName = loginUserInfo.userName;
    });
    return groupNameTmp;
  }

  populateRequestType(groupName) {
    this.requestTypes = [];
    const value = this.requestTypeMap.get(groupName);
    if (value) {
      if (value.length === 1) {
        this.resolveInquiryForm.controls['requestType'].markAsTouched();
        this.resolveInquiryForm.controls['requestType'].markAsDirty();
        this.resolveInquiryForm.get("requestType").setErrors(null);
        for (let i = 0; i < value.length; i++) {
          this.requestTypes.push({ label: value[i], value: value[i] });
        }
      } else if (this.isTaskizeInquiry) {
        this.resolveInquiryForm.get("requestType").clearValidators();
        this.resolveInquiryForm.get("requestType").setErrors(null);
        this.resolveInquiryForm.get("requestType").updateValueAndValidity();
        this.resolveInquiryForm.get('requestType').setValue('');
      }
      else {
        this.requestTypes.push({ label: 'Request:', value: '' });
        for (let i = 0; i < value.length; i++) {
          this.requestTypes.push({ label: value[i], value: value[i] });
        }
      }
    } else {
      this.requestTypes.push({ label: 'Request:', value: '' });
    }
  }

  populateTags(groupName) {
    this.tags = [];
    const value = this.tagMap.get(groupName);
    if (value) {
      if (value.length === 1) {
        this.resolveInquiryForm.controls['tags'].markAsTouched();
        this.resolveInquiryForm.controls['tags'].markAsDirty();
        for (let i = 0; i < value.length; i++) {
          this.tags.push({ label: value[i], value: value[i] });
        }
      }
      else {
        this.tags.push({ label: 'Tags:', value: '' });
        for (let i = 0; i < value.length; i++) {
          this.tags.push({ label: value[i], value: value[i] });
        }
      }
    } else {
      this.tags.push({ label: 'Tags:', value: '' });
    }
  }

  populateProcessingRegion(groupName) {
    this.processingRegions = [];
    const processingRegionList = this.processingRegionMap.get(groupName);
    if (processingRegionList) {
      if (processingRegionList.length === 1) {
        this.resolveInquiryForm.controls['processingRegion'].markAsTouched();
        this.resolveInquiryForm.controls['processingRegion'].markAsDirty();
        for (let i = 0; i < processingRegionList.length; i++) {
          this.processingRegions.push({ label: processingRegionList[i], value: processingRegionList[i] });
        }
      }
      else {
        this.processingRegions.push({ label: 'Processing Region:', value: '' });
        for (let i = 0; i < processingRegionList.length; i++) {
          this.processingRegions.push({ label: processingRegionList[i], value: processingRegionList[i] });
        }
      }
    } else {
      this.processingRegions.push({ label: 'Processing Region:', value: '' });
    }
  }

  populateRootCause(groupName: string, requestType?: string) {
    this.rootCauses = [];
    let rootCouseList = [];
    this.resolveInquiryForm.controls['rootCause'].setValue(null);
    // C170665-10 | Check whether requestTypeRootCauseFilter is enabled and there is request type - root cause mapping exist,
    // then populate the root cause with selected request type.
    let requestTypeRootCauseFilter = this.reqTypeRootCauseMap.has(groupName);
    if (requestTypeRootCauseFilter && null != this.reqTypeRootCauseMap.get(groupName)) {
      let selRequestType = this.resolveInquiryForm.get('requestType').value;
      if (!this.isNull(selRequestType) && this.isNull(requestType)) {
        requestType = selRequestType;
      }
      rootCouseList = this.getRootCauseFromMapping(groupName, requestType);

      // C170665-10 | If there is no request type - root cause mapping then load default.
      if (rootCouseList.length === 0 && !this.isNull(requestType)) {
        rootCouseList = this.getDefaultRootCause(groupName);
      }
    } else {
      rootCouseList = this.rootCauseMap.get(groupName);
    }

    if (rootCouseList) {
      if (rootCouseList.length === 1) {
        this.resolveInquiryForm.controls['rootCause'].markAsTouched();
        this.resolveInquiryForm.controls['rootCause'].markAsDirty();
        for (let i = 0; i < rootCouseList.length; i++) {
          this.rootCauses.push({ label: rootCouseList[i], value: rootCouseList[i] });
        }
        this.resolveInquiryForm.controls['rootCause'].setValue(rootCouseList[0]);
        this.isRootCauseSet = true;
      }
      else {
        this.rootCauses.push({ label: 'Root Cause:', value: '' });
        for (let i = 0; i < rootCouseList.length; i++) {
          this.rootCauses.push({ label: rootCouseList[i], value: rootCouseList[i] });
        }
      }
    }
    else {
      this.rootCauses.push({ label: 'Root Cause:', value: '' });
    }
  }

  populateInquirySource() {
    this.inquirySourceList = [];
    this.inquirySourceList.push({ label: 'Inquiry Source:', value: '' });
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      loginUserInfo.inquirySourceList.forEach(inquirySource => {
        this.inquirySourceList.push({ label: inquirySource, value: inquirySource });
      });
    });
  }

  populateInquirySubStatus() {
    this.inquirySubStatusList = [];
    this.inquirySubStatusList.push({ label: 'Inquiry Sub-Status', value: '' });

    if (this.userDataService.loggedInUserInfo.inquirySubStatusList != null) {
      let inquirySubStatus: any[] = this.userDataService.loggedInUserInfo.inquirySubStatusList;
      if (inquirySubStatus && inquirySubStatus.length > 0) {
        for (let i = 0; i <= inquirySubStatus.length; i++) {
          if (inquirySubStatus[i] === 'Completed'
            || inquirySubStatus[i] === 'Reopen - To be reviewed'
            || inquirySubStatus[i] === 'Message to review'
            || inquirySubStatus[i] === 'New') {
            this.inquirySubStatusList.push({ label: inquirySubStatus[i], value: inquirySubStatus[i], disabled: true });
          } else {
            this.inquirySubStatusList.push({ label: inquirySubStatus[i], value: inquirySubStatus[i] });
          }
        }
      }
    } else {
      console.log('Inquiey SubStatus list is null');
    }
  }

  onGroupChange(groupName) {
    this.populateRequestType(groupName.value);
    this.populateTags(groupName.value);
    this.populateProcessingRegion(groupName.value);
    this.populateRootCause(groupName.value);
    this.populateInquirySource();
    this.populateInquirySubStatus();
  }

  getCommonGroupIdsWithoutStatus(InquiryIdList, workFlowList, direction) {
    const stime = new Date();
    const assigneGroupId = [];
    const sharedIdCommonGroups = [];
    const workflowRqType = [];
    if (workFlowList != null) {

      for (const i of workFlowList) {
        if (workFlowList != null) {
          const groupId = [];
          for (const fromData of workFlowList) {
            if (fromData[0].assignedGroupId != null && fromData[0].direction.toUpperCase() == direction) {
              groupId.push(fromData[0].assignedGroupId + fromData[0].direction.toUpperCase());
              // if only one group is there set that group request
              // type.

              if (!AppUtils.isUndefinedOrNull(fromData[0].requestType) || !AppUtils.isUndefinedOrNull(fromData[0].tag) || !AppUtils.isUndefinedOrNull(fromData[0].rootCause) || !AppUtils.isUndefinedOrNull(fromData[0].processingRegion) || !AppUtils.isUndefinedOrNull(fromData[0].queryCount)) {
                workflowRqType.push({
                  groupId: fromData[0].assignedGroupId,
                  selectRequestType: fromData[0].requestType,
                  selectTag: fromData[0].tag,
                  selectRootCause: fromData[0].rootCause,
                  selectProcessingRegion: fromData[0].processingRegion,
                  queryCount: fromData[0].queryCount
                });

              }
            }
          }
          assigneGroupId.push(groupId);
        }
      }
      // collect common inquiry id for direction
      const commonGrpIds = assigneGroupId.shift().filter(function (v) {
        return assigneGroupId.every(function (a) {
          return a.indexOf(v) !== -1;
        });
      });
      console.log('getCommonGroupIdsWithoutStatus	: commonGrpIds=' + JSON.stringify(commonGrpIds));
      sharedIdCommonGroups.push({
        'ids': InquiryIdList, 'commonGroups': commonGrpIds, 'isBulckAction': true
      });
    }
    return sharedIdCommonGroups;
  }

  getCommonGroupIds(inquiryIdList, workFlowList, direction, status) {
    const stime = new Date();
    const assigneGroupId = [];
    const sharedIdCommonGroups = [];
    const workflowRqType = [];
    if (workFlowList != null) {
      if (status === 'RESOLVED' || status !== 'RESOLVED') {
        const commonInquirySource = [];
        if (workFlowList != null) {
          const groupId = [];
          for (const fromData of workFlowList) {
            if (fromData[0].assignedGroupId != null && fromData[0].direction.toUpperCase() === direction &&
              (fromData[0].status.toUpperCase() === 'OPEN' || fromData[0].status.toUpperCase() === 'RESOLVED')) {
              groupId.push(fromData[0].assignedGroupId + fromData[0].direction.toUpperCase());

              if (!AppUtils.isUndefinedOrNull(fromData[0].requestType) || !AppUtils.isUndefinedOrNull(fromData[0].tag)
                || !AppUtils.isUndefinedOrNull(fromData[0].rootCause) || !AppUtils.isUndefinedOrNull(fromData[0].processingRegion)
                || !AppUtils.isUndefinedOrNull(fromData[0].queryCount)) {
                workflowRqType.push({
                  groupId: fromData[0].assignedGroupId, selectRequestType: fromData[0].requestType,
                  selectTag: fromData[0].tag, selectRootCause: fromData[0].rootCause,
                  selectProcessingRegion: fromData[0].processingRegion, queryCount: fromData[0].queryCount
                });
              }
              if (!AppUtils.isUndefinedOrNull(fromData[0].inquirySource)) {
                if (this.commonResolveInquirySourceList.indexOf(fromData[0].inquirySource) === -1) {
                  this.commonResolveInquirySourceList.push(fromData[0].inquirySource);
                }
              }
            }
          }
          assigneGroupId.push(groupId);
        }
      }
      else {
        for (const i in workFlowList) {
          const groupId = [];
          if (workFlowList != null) {
            for (const fromData of workFlowList) {
              if (fromData[0].assignedGroupId != null && fromData[0].status.toUpperCase() === status
                && fromData[0].direction.toUpperCase() === 'IN') {
                groupId.push(fromData[0].assignedGroupId + fromData[0].status.toUpperCase());
              }
            }
          }
          assigneGroupId.push(groupId);
        }
      }

      const commonGrpIds = assigneGroupId.shift().filter(function (v) {
        return assigneGroupId.every(function (a) {
          return a.indexOf(v) !== -1;
        });
      });

      sharedIdCommonGroups.push({
        'ids': inquiryIdList, 'commonGroups': commonGrpIds, 'isBulckAction': true
      });
    }
    return sharedIdCommonGroups;
  }

  resolveInquiryService() {
    const from = [];
    let data = {};

    const requestType = this.resolveInquiryForm.get('requestType').value;
    const comments = this.resolveInquiryForm.get('comments').value;
    const tag = this.resolveInquiryForm.get('tags').value;
    const rootCause = this.resolveInquiryForm.get('rootCause').value;
    const processingRegion = this.resolveInquiryForm.get('processingRegion').value;
    const inquirySubStatus = this.resolveInquiryForm.get('inquirySubStatus').value;

    // By default Query Count should be 1.
    let qCount = this.resolveInquiryForm.get('queryCount').value;
    const queryCount = qCount ? qCount : 1;
    const inquirySource = this.resolveInquiryForm.get('inquirySource').value;

    if (!this.resolveAll) {
      from.push(this.grpName);
      data = {
        "inquiryIds": this.inquiryIdList,
        "requestType": requestType,
        "from": from,
        "comment": comments,
        "tag": tag,
        "rootCause": rootCause,
        "processingRegion": processingRegion,
        "queryCount": queryCount,
        "inquirySource": inquirySource,
        "forceUnlock": "Y",
        "inquirySubStatus": inquirySubStatus
      };
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "InquiryView", "Resolve from context menu", "Click", "Resolve from context menu", 0);
    }
    else {
      data = {
        "inquiryIds": this.inquiryIdList,
        "requestType": requestType,
        "from": this.commonRequestTypeGroupList[0].groupName,
        "comment": comments,
        "tag": tag,
        "rootCause": rootCause,
        "processingRegion": processingRegion,
        "queryCount": queryCount,
        "inquirySource": inquirySource,
        "forceUnlock": "Y",
        "inquirySubStatus": inquirySubStatus
      };
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "InquiryView", "Bulk resolve from context menu", "Click", "Bulk resolve from context menu", 0);
    }
    // C170665-119Add new QMA option as Allocation
    this.resolveInquiryForm.get('resolveAllocation').value ? data['resolveAllocation'] = "Y" : data['resolveAllocation'] = "N";

    this.contextMenuService.httpResolveInquiry(data).subscribe(response => {
      if (response.processingstatus === "true") {
        this.hideModal();
        this.resolveAll = false;
        this.resetResolveInquiry(this.resolveInquiryForm);
        this.inquiryIdList = [];
        this.hideQueries = false;
      }
    },
      error => {
        this.hideModal();
        this.resolveAll = false;
        this.resetResolveInquiry(this.resolveInquiryForm);
        console.error("Error occured while resolving Inquiry : ", error);

        // Show error message if service gets failed.
        this.showErrorDialog();
        this.errMsg = '';
        this.errMsg = 'Resolve was not successful. Please retry.';
      }
    );
  }

  mandatoryForResolveParametersForGroupName(grpName) {
    this.mandatoryForResolveFlags = [];
    let isRootCauseMandatory = false;
    let isRootCauseMandatoryWOReply = false;
    let isProcessingRegionMandatory = false;
    let isInqSourceMandatory = false;
    let isGfidMandatory = false;
    let isTagMandatory = false;
    let enableInquirySubStatus = false;
    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        loginUserInfo.myGroups.forEach(fromData => {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) && !AppUtils.isUndefinedNullOrBlank(fromData.groupName)
            && fromData.groupName.toUpperCase() === grpName.toUpperCase()) {
            if (fromData.isRootCauseMandatory) {
              isRootCauseMandatory = true;
              if (fromData.isRootCauseMandatoryWOReply) {
                isRootCauseMandatoryWOReply = true;
              }
            }
            if (fromData.isProcessingRegionMandatory) {
              isProcessingRegionMandatory = true;
            }
            if (fromData.isInquirySourceMandatory) {
              isInqSourceMandatory = true;
            }
            if (fromData.isGfidMandatory) {
              isGfidMandatory = true;
            }
            if (fromData.isTagMandatory) {
              isTagMandatory = true;
            }
            if (fromData.enableInquirySubStatus || loginUserInfo.isInquirySubStatusEnabledAtOrg)
              enableInquirySubStatus = true;
          }
        });
      });
    }
    this.mandatoryForResolveFlags.push({
      'isRootCauseMandatory': isRootCauseMandatory,
      'isProcessingRegionMandatory': isProcessingRegionMandatory,
      'isInqSourceMandatory': isInqSourceMandatory,
      'isRootCauseMandatoryWOReply': isRootCauseMandatoryWOReply,
      'isGfidMandatory': isGfidMandatory,
      'isTagMandatory': isTagMandatory,
      'enableInquirySubStatus': enableInquirySubStatus
    });
  }

  isGfidMandatoryForGroupName(grpName): boolean {
    let isGfidMandatory = false;
    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        for (const fromData of loginUserInfo.myGroups) {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) && (!AppUtils.isUndefinedNullOrBlank(fromData.groupName)
            && fromData.groupName.toUpperCase() === grpName.toUpperCase()) && fromData.isGfidMandatory) {
            isGfidMandatory = true;
            break;
          }
        }
      });
    }
    return isGfidMandatory;
  }

  isGfidAvailableForResolve(inquiryList): boolean {
    let isGfidAvailable = true;
    if (!AppUtils.isUndefinedOrNull(inquiryList)) {
      for (let index = 0; index < inquiryList.length; index++) {
        const inquiry = inquiryList[index];
        if (!AppUtils.isUndefinedOrNull(inquiry) && (AppUtils.isUndefinedNullOrBlankStrAfterTrim(inquiry.gpNum) ||
          AppUtils.isUndefinedNullOrBlankStrAfterTrim(inquiry.gfcid))) {
          isGfidAvailable = false;
          break;
        }
      }

    }
    else {
      isGfidAvailable = false;
    }
    return isGfidAvailable;
  }

  resolveForAllChecked(values: any) {
    const resolveAll = values.currentTarget.checked;

    if (resolveAll) {
      this.resolveAll = true;
      this.hideQueries = true;
      this.valiationsOnResolveAll();
    }
    else {
      this.resolveAll = false;
      if (this.inquiryIdList.length === 1) {
        this.hideQueries = false;
      }
      this.populateRequestType(this.grpName);
      this.requestTypeErrorMessageForAllGroup = "";
      this.tagErrorMessageForAllGroup = "";
      this.rootCauseErrorMessageForAllGroup = "";
      this.processingRegionErrorMessageForAllGroup = "";
      this.inqSourceErrorMessageForAllGroup = "";

      // Check if the resolve is uncheck.
      this.showSubmitHideForceResolveMsg();
      this.isLockedSameAsLoginUser(this.inquiryIdList, this.selectedMenuItem);
    }
  }

  resolveAllocationChecked(values: any) {
  }
  valiationsOnResolveAll() {
    // We need to get the workflows of the selected inquiry, initially it takes oinly one workflow
    const groupName: string = this.selectedMenuItem[0].openGroups;
    const group: MyGroup = this.userDataService.loggedInUserInfo.myGroups.find(grp => grp.groupName === groupName);

    const inqLevelWorkFlowList = [];
    if (group) {
      this.inboxService.getInquiryIdSelected({
        id: this.selectedMenuItem[0]._id,
        selectInqAssignedGroupId: [group.id]
      }).subscribe(selectedMail => {
        inqLevelWorkFlowList.push({ 'workflows': selectedMail.inquiry.workflows });
        this.commonRequestTypeGroupList = this.getCommonWorkflowData(inqLevelWorkFlowList, 'IN');
        console.log('commonRequestTypeGroupList:', this.commonRequestTypeGroupList);

        // - to validate lock validation.
        this.mergeFieldsForResolveAll(inqLevelWorkFlowList);
      }, error => {
        throw new Error('Failed to Resolve All');
      });
    }
  }

  mergeFieldsForResolveAll(inqLevelWorkFlowList: any) {

    if (!this.isGfidMandatory) {
      const groupArr = this.commonRequestTypeGroupList[0].groupName;
      if (groupArr) {
        for (let idx = 0; idx < groupArr.length; idx++) {
          const grpName = groupArr[idx];
          const gfidReq = this.isGfidMandatoryForGroupName(grpName);
          if (gfidReq) {
            this.isGfidMandatory = gfidReq;
            break;
          }
        }
      }
    }
    if (this.isGfidMandatory && !this.isGfidAvailable) {
      this.gfidMissingErr = "GFPID/GFCID is missing. Inquiry(s) cannot be resolved.";
      this.disableSubmit = true;
      return;
    }

    if (!this.isTagMandatory) {
      const groupArrTag = this.commonRequestTypeGroupList[0].groupName;
      if (groupArrTag) {
        for (let idx1 = 0; idx1 < groupArrTag.length; idx1++) {
          const grpNameTag = groupArrTag[idx1];
          const tagReq = this.isTagMandatoryForGroupName(grpNameTag);
          if (tagReq) {
            this.isTagMandatory = tagReq;
            break;
          }
        }
      }
    }

    if (this.isTagMandatory && !this.isTagAvailable) {
      this.resolveInquiryForm.controls['tags'].setValidators(Validators.required);
      this.resolveInquiryForm.controls['tags'].setErrors({ notUnique: true });
      this.resolveInquiryForm.controls['tags'].updateValueAndValidity();
      console.log('Tags should be mandatory');
    }

    this.disableSubmit = false;

    // Check for Inquiry locked by other user
    if (inqLevelWorkFlowList[0].workflows != null) {
      inqLevelWorkFlowList[0].workflows.forEach(function (lockedData) {
        if (!AppUtils.isUndefinedOrNull(lockedData.lockedBy) && "Y" === lockedData.lock && lockedData.lockedBy !== this.loggedInUserName) {
          this.disableSubmit = true;
          return;
        }
      });
    }
    if (!this.resolveInquiryForm.get('forceResolve').value) {
      this.disableSubmit = false;
      this.lockErrorMsg = "";
    }

    this.requestTypes = [];
    this.requestTypes.push({ label: 'Request:', value: '' });
    if (!AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList) &&
      !AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList[0].getCommonReqType) &&
      this.commonRequestTypeGroupList[0].getCommonReqType.length > 0) {
      const commonReqType = this.commonRequestTypeGroupList[0].getCommonReqType;
      commonReqType.forEach(reqType => {
        this.requestTypes.push({ 'label': reqType, 'value': reqType });
      });
      this.resolveAll = true;
    } else if (this.isTaskizeInquiry) {
      this.resolveAll = true
    } else {
      this.requestTypeErrorMessageForAllGroup = "No common request types";
      this.disableSubmit = true;
    }

    this.tags = [];
    this.tags.push({ label: 'Tags:', value: '' });
    if (!AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList) &&
      !AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList[0].commonTags) &&
      this.commonRequestTypeGroupList[0].commonTags.length > 0) {
      const commonTags = this.commonRequestTypeGroupList[0].commonTags;
      commonTags.forEach(tag => {
        this.tags.push({ label: tag, value: tag })
      });
      this.resolveAll = true;
    }
    else {
      if (this.isTagMandatory) {
        this.tagErrorMessageForAllGroup = "No common tags";
        this.disableSubmit = true;
      }
    }

    if (!this.isRootCauseMandatory) {
      this.rootCauseRequired = [];
      const groupArr = this.commonRequestTypeGroupList[0].groupName;
      if (groupArr) {
        for (let idx = 0; idx < groupArr.length; idx++) {
          const grpName = groupArr[idx];
          this.rootCauseRequired = this.getGroupRootCauseByName(grpName);
          if (!AppUtils.isUndefinedOrNull(this.rootCauseRequired) &&
            (this.rootCauseRequired[0].isRootCauseMandatory || this.rootCauseRequired[0].isRootCauseMandatoryWOReply)) {
            this.resolveInquiryForm.controls['rootCause'].setValidators(Validators.required);
            this.resolveInquiryForm.controls['rootCause'].setErrors({ notUnique: true });
            this.resolveInquiryForm.controls['rootCause'].updateValueAndValidity();
            console.log('Root Cause should be mandatory');
            this.isRootCauseMandatory = true;
            break;
          }
        }
      }
    }

    this.rootCauses = [];
    this.rootCauses.push({ label: 'Root Cause:', value: '' });
    if (!AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList) &&
      !AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList[0].commonRootCause)
      && this.commonRequestTypeGroupList[0].commonRootCause.length > 0) {
      const commonRootCauses = this.commonRequestTypeGroupList[0].commonRootCause;
      commonRootCauses.forEach(rootCause => {
        this.rootCauses.push({ label: rootCause, value: rootCause });
      });
      this.resolveAll = true;
    }
    else {
      if (this.isRootCauseMandatory) {
        this.rootCauseErrorMessageForAllGroup = "No common root cause";
        this.disableSubmit = true;
      }
    }

    if (!this.isProcessingRegionMandatory) {
      const groupArr = this.commonRequestTypeGroupList[0].groupName;
      if (groupArr) {
        for (let idx = 0; idx < groupArr.length; idx++) {
          const grpName = groupArr[idx];
          const processingRegionRequired = this.getGroupProcessingRegionByName(grpName);
          if (processingRegionRequired) {
            this.resolveInquiryForm.controls['processingRegion'].setValidators(Validators.required);
            this.resolveInquiryForm.controls['processingRegion'].setErrors({ notUnique: true });
            this.resolveInquiryForm.controls['processingRegion'].updateValueAndValidity();
            console.log('Processing region should be mandatory');
            this.isProcessingRegionMandatory = processingRegionRequired;
            break;
          }
        }
      }
    }

    this.processingRegions = [];
    this.processingRegions.push({ label: 'Processing Region:', value: '' });
    if (!AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList) &&
      !AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList[0].commonProcessingRegion)
      && this.commonRequestTypeGroupList[0].commonProcessingRegion.length > 0) {
      const commonProcessingRegions = this.commonRequestTypeGroupList[0].commonProcessingRegion;
      commonProcessingRegions.forEach(processingRegion => {
        this.processingRegions.push({ label: processingRegion, value: processingRegion });
      });
      this.resolveAll = true;
    }
    else {
      if (this.isProcessingRegionMandatory) {
        this.processingRegionErrorMessageForAllGroup = "No common processing region";
        this.disableSubmit = true;
      }
    }

    if (!this.isInqSourceMandatory) {
      const groupArr = this.commonRequestTypeGroupList[0].groupName;
      if (groupArr) {
        for (let idx = 0; idx < groupArr.length; idx++) {
          const grpName = groupArr[idx];
          const inqSourceReqired = this.isInquirySourceMandatoryForGroupName(grpName);
          if (inqSourceReqired) {
            this.resolveInquiryForm.controls['inquirySource'].setValidators(Validators.required);
            this.resolveInquiryForm.controls['inquirySource'].setErrors({ notUnique: true });
            this.resolveInquiryForm.controls['inquirySource'].updateValueAndValidity();
            console.log('Inquiry Source should be mandatory');
            this.isInqSourceMandatory = inqSourceReqired;
            break;
          }
        }
      }
    }

    if (!AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList) &&
      !AppUtils.isUndefinedOrNull(this.commonRequestTypeGroupList[0].commonInquirySource)) {
      if (this.commonRequestTypeGroupList[0].commonInquirySource.length === 1) {
        this.inquirySourceList = [];
        this.inquirySourceList.push({ label: 'Inquiry Source:', value: '' });
        const commonInquirySource = this.commonRequestTypeGroupList[0].commonInquirySource;
        commonInquirySource.forEach(inquirySource => {
          this.inquirySourceList.push({ label: inquirySource, value: inquirySource });
        });
        this.resolveAll = true;
      }
      else if (this.commonRequestTypeGroupList[0].commonInquirySource.length > 1) {
        this.inquirySourceList = [];
        this.inquirySourceList.push({ label: 'Inquiry Source:', value: '' });
        this.inqSourceErrorMessageForAllGroup = "No common inquiry source. Inputed value will be overwritten for selected records";
        this.disableSubmit = true;
      }
    }
  }

  getCommonWorkflowData(inqLevelWorkFlowList, direction) {
    const assigneGroupId = [];
    const requestTypeArry = [];
    const response = [];
    const groupName = [];
    const tagArr = [];
    const rootCauseArr = [];
    const processingRegionArr = [];
    const commonInquirySourceArr = [];

    if (inqLevelWorkFlowList[0].workflows != null) {
      const groupId = [];
      for (const fromData of inqLevelWorkFlowList[0].workflows) {
        if (fromData.assignedGroupId != null && fromData.direction.toUpperCase() === direction && fromData.status.toUpperCase() === 'OPEN') {
          assigneGroupId.push(fromData.assignedGroupId);
          if (!AppUtils.isUndefinedOrNull(fromData.inquirySource)) {
            if (commonInquirySourceArr.indexOf(fromData.inquirySource) === -1) {
              commonInquirySourceArr.push(fromData.inquirySource);
            }
          }
        }
        //check if one of the Workflow version is locked by a different user & another user tries to Resolve the same inquiry with different Workflow version
        //using "Resolve For All My Grps" then allow the user to 'Force Resolve' the inquiry & display Force Resolve message
        if (!AppUtils.isUndefinedOrNull(fromData.lockedBy) && "Y" === fromData.lock && fromData.lockedBy !== this.loggedInUserName) {
          this.lockErrorMsg = 'One or more inquiries are locked by different user and cannot be Resolved. Please select \'Force Resolve\' to continue with Resolving the inquiries.';
          this.errMsg = '';
          this.errMsg = this.lockErrorMsg;
          this.hideSubmitShowForceResolveMsg();
        }
      }
    }
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      loginUserInfo.myGroups.forEach(fromData => {
        if (fromData.groupName != null && fromData.id != null && (assigneGroupId.indexOf(fromData.id) > -1)) {
          groupName.push(fromData.groupName);
          if (!AppUtils.isUndefinedOrNull(fromData.requestTypes)) {
            requestTypeArry.push(fromData.requestTypes);
          } else {
            requestTypeArry.push([]);
          }

          if (!AppUtils.isUndefinedOrNull(fromData.rootCauseList)) {
            rootCauseArr.push(fromData.rootCauseList);
          } else {
            rootCauseArr.push([]);
          }

          if (!AppUtils.isUndefinedOrNull(fromData.processingRegionList)) {
            processingRegionArr.push(fromData.processingRegionList);
          } else {
            processingRegionArr.push([]);
          }

          if (!AppUtils.isUndefinedOrNull(fromData.tags)) {
            tagArr.push(fromData.tags);
          } else {
            tagArr.push([]);
          }
        }
      });
    });

    //collect all common request type
    const getCommonReqType = requestTypeArry.shift().filter(function (v) {
      return requestTypeArry.every(function (a) {
        return a.indexOf(v) !== -1;
      });
    });

    //collect all common tags
    const commonTags = tagArr.shift().filter(function (v) {
      return tagArr.every(function (a) {
        return a.indexOf(v) !== -1;
      });
    });

    //collect all common root cause
    const commonRootCause = rootCauseArr.shift().filter(function (v) {
      return rootCauseArr.every(function (a) {
        return a.indexOf(v) !== -1;
      });
    });

    //collect all common processing region
    const commonProcessingRegion = processingRegionArr.shift().filter(function (v) {
      return processingRegionArr.every(function (a) {
        return a.indexOf(v) !== -1;
      });
    });

    response.push({
      'getCommonReqType': getCommonReqType,
      'groupName': groupName,
      'commonTags': commonTags,
      'commonRootCause': commonRootCause,
      'commonProcessingRegion': commonProcessingRegion,
      'commonInquirySource': commonInquirySourceArr
    });
    return response;
  }

  isTagMandatoryForGroupName(grpName) {
    let isTagMandatory = false;
    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        for (const fromData of loginUserInfo.myGroups) {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) &&
            (!AppUtils.isUndefinedNullOrBlank(fromData.groupName)
              && fromData.groupName.toUpperCase() === grpName.toUpperCase()) && fromData.isTagMandatory) {
            isTagMandatory = true;
            break;
          }
        }
      });
    }
    return isTagMandatory;
  }

  getGroupRootCauseByName(grpName) {
    const rootCause = [];
    let isRootCauseMandatory = false;
    let isRootCauseMandatoryWOReply = false;
    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        for (const fromData of loginUserInfo.myGroups) {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) && fromData.isRootCauseMandatory &&
            (!AppUtils.isUndefinedNullOrBlank(fromData.groupName) && fromData.groupName.toUpperCase() === grpName.toUpperCase())) {
            isRootCauseMandatory = true;
            if (fromData.isRootCauseMandatoryWOReply) {
              isRootCauseMandatoryWOReply = true;
            }
            break;
          }
        }
      });
    }

    rootCause.push({ 'isRootCauseMandatory': isRootCauseMandatory, 'isRootCauseMandatoryWOReply': isRootCauseMandatoryWOReply });
    return rootCause;
  }

  getGroupProcessingRegionByName(grpName) {
    let processingRegion = false;
    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        for (const fromData of loginUserInfo.myGroups) {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) && fromData.isProcessingRegionMandatory &&
            (!AppUtils.isUndefinedNullOrBlank(fromData.groupName) && fromData.groupName.toUpperCase() === grpName.toUpperCase())) {
            processingRegion = true;
            break;
          }
        }
      });
    }
    return processingRegion;
  }

  isInquirySourceMandatoryForGroupName(grpName) {
    let isInqSourceMandatory = false;
    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        for (const fromData of loginUserInfo.myGroups) {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) && fromData.isInquirySourceMandatory &&
            (!AppUtils.isUndefinedNullOrBlank(fromData.groupName) && fromData.groupName.toUpperCase() === grpName.toUpperCase())) {
            isInqSourceMandatory = true;
            break;
          }
        }
      });
      return isInqSourceMandatory;
    }
  }

  validateRequestType(fromGrpName, inqRequestType) {
    if (!this.isRequestTypeMappingDone(fromGrpName, inqRequestType)) {
      this.errMsg = "<div class='customDialogContentScroll'>Please map the request type: &nbsp;<font  color='blue'><b>" +
        inqRequestType + "</b></font>" + " &nbsp;with Generic Request Type from the group admin screen for group:&nbsp;" +
        "<font  color='blue'><b>" + fromGrpName + "</b></font>" + "&nbsp;&nbsp;" + "<br />"
        + "<input type='button' class='k-button k-primary' id ='genericReqTypeCheck' style='margin-left:125px; width: 75px;' value='Ok' onclick='closeErrorDialog(this);'></div>";
      if (!AppUtils.isUndefinedNullOrBlank(inqRequestType)) {
        if (this.highlevelreqtypeFlag) {
          this.showErrorDialog();
          return false;
        }
      }
    }
  }

  isRequestTypeMappingDone(fromGrpName, inqRequestType) {
    let isRequestTypeMappingDone = false;
    let fromData = this.getGroupFromGroupName(fromGrpName);
    let requestTypeMappings = fromData.requestTypeMappings;
    if (!AppUtils.isUndefinedNullOrBlank(requestTypeMappings)) {
      for (let idx = 0; idx < requestTypeMappings.length; idx++) {
        if (!AppUtils.isUndefinedNullOrBlank(requestTypeMappings[idx]) && !AppUtils.isUndefinedNullOrBlank(requestTypeMappings[idx].requestType)
          && !AppUtils.isUndefinedNullOrBlank(requestTypeMappings[idx].highlevelRequestType)) {
          let reqType = requestTypeMappings[idx].requestType;
          let highLevelReqType = requestTypeMappings[idx].highlevelRequestType;
          if (reqType === inqRequestType && !AppUtils.isUndefinedNullOrBlank(highLevelReqType)) {
            isRequestTypeMappingDone = true;
            break;
          }
          else {
            isRequestTypeMappingDone = false;
          }
        }
      }
    }
    return isRequestTypeMappingDone;
  }

  showErrorDialog() {
    this.displayError = true;
  }

  getGroupFromGroupName(selectedFromGroup): MyGroup {
    let group;
    if (!this.isNull(selectedFromGroup)) {
      group = new MyGroup();
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        loginUserInfo.myGroups.forEach(myGroup => {
          if (myGroup.groupName && myGroup.groupName.toUpperCase() === selectedFromGroup.toUpperCase()) {
            group = myGroup;
          }
        });
      });
    }
    return group;
  }

  closeModal(str: any) {
    if (str === "error") { this.displayError = false; }
  }

  isLockedSameAsLoginUser(InquiryIdList, workFlowList) {
    let lockInquiryCount = 0;
    if (workFlowList && workFlowList[0].workflows != null) {
      workFlowList[0].workflows.forEach(lockedData => {
        if (!AppUtils.isUndefinedOrNull(lockedData.lockedBy) &&
          "Y" === lockedData.lock && lockedData.lockedBy !== this.loggedInUserName && lockInquiryCount >= 1) {
          this.lockErrorMsg = 'One or more inquiries are locked by different user and cannot be Resolved.Please select \'Force Resolve\' to continue with Resolving the inquiries.';
          this.errMsg = '';
          this.errMsg = this.lockErrorMsg;
          this.hideSubmitShowForceResolveMsg();
        }
        else {
          if (!AppUtils.isUndefinedOrNull(lockedData.lock) && "Y" === lockedData.lock
            && lockedData.lockedBy !== this.loggedInUserName && lockInquiryCount === 0) {
            ++lockInquiryCount;
            this.lockErrorMsg = 'This inquiry is locked by [' + lockedData.lockedBy + '] and cannot be Resolved.Please select \'Force Resolve\' to continue with Resolving the inquiry.';
            this.errMsg = '';
            this.errMsg = this.lockErrorMsg;
            this.hideSubmitShowForceResolveMsg();
          }
        }
      });
    }
  }

  //Function to disable the Submit button in the resolve window & display Force Resolve error message & Force Resolve Checkbox.
  hideSubmitShowForceResolveMsg() {
    this.disableSubmit = true;
    this.checkBoxForceResolveDiv.nativeElement.style.setProperty('display', 'flex', 'important');
  }
  //Function to enable the Submit button in the resolve window & hide Force Resolve error message & Force Resolve Checkbox.
  showSubmitHideForceResolveMsg() {
    // this.checkBoxForceResolveDiv.nativeElement.style.display = "none !important";
    this.checkBoxForceResolveDiv.nativeElement.style.setProperty('display', 'none', 'important');
    //- Remove the message and uncheck force resolve
    this.lockErrorMsg = "";
    this.resolveInquiryForm.get('forceResolve').setValue(false);

  }

  forceResolve(values: any) {
    const forceResolve = values.currentTarget.checked;

    if (forceResolve) {
      this.disableSubmit = false;
      this.checkBoxForceResolveDiv.nativeElement.style.setProperty('display', 'flex', 'important');
      this.lockErrorMsg = '';
      this.resolveInquiryForm.get('forceResolve').setValue(true);
    }
    else {
      this.disableSubmit = true;
      this.checkBoxForceResolveDiv.nativeElement.style.setProperty('display', 'flex', 'important');
      this.lockErrorMsg = this.errMsg;
      this.resolveInquiryForm.get('forceResolve').setValue(false);
    }
  }

  /**
   * Method to update the root cause based on selected request type.
   * @param requestType
   */
  onRequestTypeChange(requestType: any): void {
    let requestTypeRootCauseFilter = this.reqTypeRootCauseMap.has(this.grpName);
    if (requestTypeRootCauseFilter) {
      this.populateRootCause(this.grpName, requestType.value);
    }
  }

  /**
   * Method to get the list of root cause form the selected request type and the group
   * from request type and root cause mapping.
   * @param groupName
   * @param requestType
   */
  getRootCauseFromMapping(groupName: string, requestType: string): string[] {
    let rootCauses = [];
    if (!this.isNull(requestType)) {
      let reqTypeRootCauseMap = this.reqTypeRootCauseMap.get(groupName);
      if (reqTypeRootCauseMap) {
        for (const [key, value] of Object.entries(reqTypeRootCauseMap)) {
          if (key === requestType) {
            if (Array.isArray(value)) {
              rootCauses = [...value];
            }
            break;
          }
        }
      }
    }
    return rootCauses;
  }

  /**
   * Method to get the default root cause for the specific group.
   *
   * @param groupName
   */
  getDefaultRootCause(groupName: string): string[] {
    let rootCauses = [];
    this.rootCauseMap.forEach((value: string[], key: string) => {
      if (!this.isNull(value) && key === groupName) {
        if (Array.isArray(value)) {
          rootCauses = [...value];
        }
      }
    });
    return rootCauses;
  }

  /*  changes regarding C170665-365If GFPID is mandatory,
    provide an option to assign gfcid click on resolve option */
  callGFIDWindow() {
    this.GfidSearchComponent.groupName = this.grpName;
    this.GfidSearchComponent.triggerFalseClick();
  }
  getGFID(val: any) {
    this.contextServiceObject.httpSetGfid(this.selectedMenuItem, val).subscribe(res => console.log(res));
    const status = this.isGfidAvailableForResolve(this.selectedMenuItem);
    this.isGfidAvailable = true;
    this.isGfidMandatory = false;
    if (!this.isGfidMandatory && this.isGfidAvailable) {
      this.gfidMissingErr = "";
      this.disableSubmit = false;
      return;
    }
  }
  showHideMoreFields() {
    this.toggleMoreFields = !this.toggleMoreFields;
    this.showMoreFields = !this.showMoreFields;
  }

  taskizeResolve() {
    this.resolveInquiryForm.get('resolveAll').setValue(true)
    let checkedResolveBoxForAll = {
      currentTarget: {
        checked: true
      }
    }
    this.resolveForAllChecked(checkedResolveBoxForAll)
    this.resolveInquiryForm.controls['resolveAll'].disable();
  }
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  MIS_DASHBOARD: 'XXXX',

  ISSUER: 'https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_CFl64N00x',
  REDIRECT_URI: 'https://preview-ui.dev.qwyn.deltacapita.net',
  CLIENT_ID: '7hndvf7n55oco4r4vh6gbgbgqi',
  SCOPE: 'openid profile',
  RESPONSE_TYPE: 'code',
  QWYNUI_URL: 'https://preview-ui.dev.qwyn.deltacapita.net',
  QMAAPI_URL: 'https://qmauiapi.dev.qwyn.deltacapita.net',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component } from '@angular/core';
import { QmaConstant } from '../../constant/qma-constant';
import { InboxService } from '../../services/inbox.service';

@Component({
    selector: "child-cell",
    template: `
        <div draggable="true" (dragstart)="onDragStart($event)"><i class="ag-icon ag-icon-arrows"></i></div>`
})
export class DragGripCellRenderer {
    public params: any;

    constructor(private inboxService: InboxService) { }
    agInit(params: any): void {
        this.params = params;
    }

    onDragStart(event: any) {
        if (this.params && this.params.node && this.params.node.data) {
            console.log("onDragStart, params", this.params);
            const data = this.getData();
            event.dataTransfer.setData("text/plain", QmaConstant.QMA_DND_HEADER + this.getData());
            event.dataTransfer.payload = data;
            this.inboxService.triggerInquiryDrag({ qma: true, active: true });
        }
    }

    getData() {
        let data: any = {};
        if (this.params.node.data) {
            data.id = this.params.node.data._id;
            data.assignedGroupIds = [];
            if (this.params.node.data.workflows) {
                this.params.node.data.workflows.forEach((wf) => {
                    if (wf.assignedGroupId) {
                        data.assignedGroupIds.push(wf.assignedGroupId);
                    }
                });
            }
        }
        const result = encodeURIComponent(JSON.stringify(data));
        return result;
    }
}